<template>
  <div class="md-layout">words</div>
</template>

<script>
export default {
  name: "WordsPage",
};
</script>

<style lang="scss" scoped></style>
